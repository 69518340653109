<template>
  <div>
  <div class="car-view">
    <b-carousel
      v-if="images[id-1]"
      id="carousel-1"
      :interval="4000"
      controls
      indicators
      background="#EFEFEF"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      label-next=""
      label-prev=""
      class="img-style"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
      v-for="(car, index) in images[id-1].car"
      :key="index"
      :img-src="require(`../assets/car-${id}/${car}`)"
      ></b-carousel-slide>
    </b-carousel>
    <div class="car-view-item" v-if="images[id-1]">
      <div>
        <h3>{{ data.carName }}</h3>
          <div class="right-bar">
            <div class="right-bar-item">
                <img src="@/assets/icons/image-date.png" alt="">
                <div class="item-inside">
                  <h5>Rok výroby</h5>
                  <p>{{ data.date }}</p>
                </div>
            </div>
            <div class="right-bar-item">
                <img src="@/assets/icons/image-motor.png" alt="">
                <div class="item-inside">
                  <h5>Výkon motora</h5>
                  <p>{{ data.motor }}</p>
                </div>
            </div>
            <div class="right-bar-item">
                <img src="@/assets/icons/image-gas.png" alt="">
                <div class="item-inside">
                  <h5>Typ paliva</h5>
                  <p>{{ data.type }}</p>
                </div>
            </div>
            <div class="right-bar-item">
                <img src="@/assets/icons/image-liter.png" alt="">
                <div class="item-inside">
                  <h5>Spotreba</h5>
                  <p>{{ data.liter }}</p>
                  </div>
            </div>
            <div class="right-bar-item">
                <img src="@/assets/icons/image-people.png" alt="">
                <div class="item-inside">
                  <h5>Max. posádka</h5>
                  <p>{{ data.crew }}</p>
                </div>
            </div>
          </div>
      </div>
      <div class="cost-view">
        <h3>Cenník</h3>
        <div class="cost-view-item">
          <p>1 - 4 dní</p>
          <p>{{ data.prize.firstLevel }} na deň <span>{{data.maxKm.firstLevel}}</span></p>
        </div>
        <hr>
        <div class="cost-view-item">
          <p>5 - 15 dní</p>
          <p>{{ data.prize.secondLevel }} na deň <span>{{data.maxKm.secondLevel}}</span></p>
        </div>
        <hr>
        <div class="cost-view-item">
          <p>16 - 30 dní</p>
          <p>{{ data.prize.thirdLevel }} na deň <span>{{data.maxKm.thirdLevel}}</span></p>
        </div>
        <hr>
        <div class="cost-view-item">
          <p>30+ dní</p>
          <p>{{ data.prize.fourthLevel }} na deň <span>{{data.maxKm.fourthLevel}}</span></p>
        </div>
        <div class="info-cost">
          <p>*Po prekročení denného limitu je doplatok 0,20 €/km</p>
          <p>*Depozit za motorové vozidlo je {{ data.prize.moneyInFront }}, ktorý je automatický
            vrátení po odovzdaní vozidla bez poškodenia.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="interest">
    <h3>Máte záujem ?</h3>
    <p>Stačí nám zavolať.</p>
    <div>
      <a href="tel:+421908333103">0908 333 103</a>
      <a href="tel:+42118827332">0918 827 332</a>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        id: null,
        images: [{
          car: [
          'IMG_1.jpg',
          'IMG_2.jpg',
          'IMG_3.jpg',
          'IMG_4.jpg',
          'IMG_5.jpg',
          'IMG_6.jpg',
          'IMG_7.jpg',
          'IMG_8.jpg',
          'IMG_9.jpg',
          'IMG_10.jpg',
          'IMG_11.jpg'
          ]
        },
        {
          car: [
            'IMG_1.jpg',
            'IMG_2.jpg',
            'IMG_3.jpg',
            'IMG_4.jpg',
            'IMG_5.jpg',
            'IMG_6.jpg',
            'IMG_7.jpg',
            'IMG_8.jpg',
            'IMG_9.jpg',
            'IMG_10.jpg'
            ]
        },
        {
          car: [
            'IMG_1.jpg',
            'IMG_2.jpg',
            'IMG_3.jpg',
            'IMG_4.jpg',
            'IMG_5.jpg',
            'IMG_6.jpg',
            'IMG_7.jpg'
            ]
        },
        {
          car: [
            'IMG_1.jpg',
            'IMG_2.jpg',
            'IMG_3.jpg',
            'IMG_4.jpg',
            'IMG_5.jpg',
            'IMG_6.jpg',
            'IMG_7.jpg',
            'IMG_8.jpg',
            'IMG_9.jpg',
            ]
        },
        {
          car: [
            'IMG_1.jpg',
            'IMG_2.jpg',
            'IMG_3.jpg',
            'IMG_4.jpg',
            'IMG_5.jpg',
            'IMG_6.jpg',
            'IMG_7.jpg',
            'IMG_8.jpg',
            ]
        },
        {
          car: [
            'IMG_1.jpg',
            'IMG_2.jpg',
            'IMG_3.jpg',
            'IMG_4.jpg',
            'IMG_5.jpg',
            'IMG_6.jpg',
            'IMG_7.jpg',
            'IMG_8.jpg',
            ]
        },
        {
          car: [
            'IMG_1.jpeg',
            'IMG_2.jpeg',
            'IMG_3.jpeg',
            'IMG_4.jpeg',
            'IMG_5.jpeg',
            'IMG_6.jpeg',
            'IMG_7.jpeg',
            ]
        },
      ]
      }
    },
    computed: {
    ...mapGetters([
        'getData'
    ]),
    data(){
      return this.getData[this.id-1]
    }
  },
    mounted(){
      this.id = this.$route.params.id;
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
  }
</script>

<style lang="scss" scoped>
.car-view{
  width: 1400px;
  margin: 100px auto;
  background-color: #EFEFEF;
  border-radius: 25px;
  display: flex;
  .img-style{
    width: 800px;
    height: 600px;
    overflow: hidden;
    border-radius: 25px 0 0 25px;
  }
}
.car-view-item{
  /*max-width: 800px;
  width: 100%;*/
  h3{
    font-weight: 700;
    font-family: 'UniviaPro-Bold';
    margin-left: 40px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 10px;
  }
  .right-bar{
    margin-left: 60px;
    font-weight: 400;
    .right-bar-item{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .item-inside{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      img{
        margin-right: 20px;
        width: 25px;
      }
      p{
        margin-bottom: 0;
      }
    }
  }
  .cost-view{
    h3{
      margin-top: 15px;
    }
    .cost-view-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 40px;
      margin-left: 60px;
      p{
        margin-bottom: 10px;
        span{
          opacity: 0.5;
          font-size: 12px;
        }
      }
    }
    hr{
      margin-left: 60px;
      margin-top: 0;
      margin-bottom: 5px;
    }
    .info-cost{
      margin-left: 60px;
      p{
        margin-bottom: 0;
        font-size: 10px;
      }
    }
  }
}
.img-new{
  width: 300px;
}
.interest{
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h3{
    font-weight: 700;
    font-family: 'UniviaPro-Bold';
    border-bottom: 1px solid #DEDEDE;
  }
  a{
    margin-right: 10px;

  }
}
@media screen and (max-width: 1800px) {
  .car-view-item{
    width: 90% !important;
  }
.car-view{
  flex-direction: column;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  .img-style{
    border-radius: 25px 25px 0 0;
  }
}
.info-cost{
  padding-bottom: 30px;
}
}
@media screen and (max-width: 900px) {
.car-view{
  width: 500px !important;
  margin-top: 50px !important;
  .img-style{
    width: 500px !important;
    height: 370px !important;
  }
}
}
@media screen and (max-width: 550px) {
.car-view{
  width: 300px !important;
  margin-top: 20px !important;
  .img-style{
    width: 300px !important;
    height: 230px !important;
  }
}
.car-view-item{
  h3{
    font-size: 16px !important;
    margin-left: 10px !important;
    margin-top: 10px !important;
  }
}
.right-bar{
  margin-left: 20px !important;
}
.item-inside{
  h5{
    font-size: 16px !important;
  }
  p{
    font-size: 12px !important;
  }
}
.cost-view{
  hr{
    margin-left: 20px !important;
  }
}
.cost-view-item{
  margin-left: 20px !important;
  p{
    font-size: 12px !important;
  }
}
.info-cost{
  margin-left: 20px !important;
}
.interest{
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  h3{
    font-size: 16px !important;
  }
  p{
    font-size: 12px !important;
    margin-bottom: 0 !important;
  }
  a{
    font-size: 12px !important;
  }
}
}
</style>

<style lang="scss">
  .img-fluid{
    height: 100% !important;
  }
  .carousel-control-prev-icon {
  background-image: url(../assets/icons/image-arrow-left.png) !important;
  margin-right: 50px;
  }
  .carousel-control-next-icon {
  background-image: url(../assets/icons/image-arrow-right.png) !important;
  margin-left: 50px;
  }
  @media screen and (max-width: 550px) {
    .carousel-control-prev-icon {
  margin-right: 20px;
  }
  .carousel-control-next-icon {
  margin-left: 20px;
  }
  }
</style>